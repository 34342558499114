.products {
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 1rem;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 4rem;
    }

    &-item {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
        padding: 1rem;
        margin-bottom: 1rem;
        transition: box-shadow $mainTransition;
        height: calc(100% - 1rem);

        &:hover {
            box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);

            .products-item-title {
                text-decoration: underline;
                color: $linksColor;
            }
        }

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
            
        }

        &-img {
            margin-bottom: 1.875rem;
            border-radius: 0.375rem;
            width: 100%;
            block-size: auto;
        }

        &-title {
            font-weight: 500;
            line-height: 1.375rem;
            margin-bottom: 1.5rem;

            &:hover {
                text-decoration: none !important;
            }
        }

        &-specifications {
            line-height: 0.875rem;
            margin-bottom: 1rem;

            &-item {
                padding: 0.5rem 1rem;

                & > span:last-child {
                    text-align: right;
                }

                &:nth-child(odd) {
                    background: #F8FAFB;
                }

                &:not(:first-child) > span:last-child {
                    font-weight: 300;
                }

                &:first-child > span:last-child {
                    color: $linksColor;
                }
            }
        }

        &-rating {
            margin-bottom: 1.875rem;

            &-star {
                background: url("../img/sprite.svg#include--rating-star") no-repeat;
                width: 15px;
                height: 15px;
                display: inline-block;
                cursor: pointer;

                &-is {
                    background: url("../img/sprite.svg#include--rating-star-is") no-repeat;
                }

                & + & {
                    margin-left: 2px;
                }
            }
        }

        &-reviews {
            margin-left: 0.625rem;
        }

        &-price {
            margin-bottom: 1.5rem;

            &-value {
                font-weight: 700;
                line-height: 1.375rem;
            }

            &-old {
                text-decoration: line-through;
            }
        }

        .btn {
            padding-top: 0.875rem;
            padding-bottom: 0.875rem;
        }

    }
}

.category-product-box {
    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .section-title {
        padding: 1rem 0 2.5rem;
    }

    .products {
        padding-bottom: 1rem;
    }
}