.account {

    &-container {
        @include media-breakpoint-down(sm) {
            padding: 0;

            > .row {
                margin: 0;

                > [class*="col-"] {
                    padding-right: 0;
                    padding-left: 0;
                    overflow: hidden;
                }
            }
        }
    }

    &-box {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
        transition: box-shadow $mainTransition;
        width: 100%;
        padding: 2.5rem 1rem;

        @include media-breakpoint-up(md) {
            padding: 2.5rem 1.5rem;
        }

        &-title {
            font-size: 1.625rem;
        }
        
        .form-control {
            width: 100%;

            @include media-breakpoint-up(sm) {
                max-width: 300px;
            }
        }

        &:hover {
            box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05)
        }
    }

    &-login {
        gap: 1.5rem;

        @include media-breakpoint-up(lg) {
            gap: 1.75rem;
        }
        
        .account-box {

            @include media-breakpoint-up(lg) {
                flex: 0 0 calc(50% - 0.875rem);
                width: calc(50% - 0.875rem);
            }
        }

        &-exist {
            a {
                text-decoration: none;
                font-weight: 500;
            }
        }

        &-register {
            background: #303C4E;
            color: #FFFFFF;

            .account-box-text {
                font-weight: 300;
            }
        }

        &-socials {
            &-box {
                gap: 0.5rem;
            }

            &-item {
                padding: 0.5rem 1rem;
                border: 1px solid #E8ECEF;
                transition: box-shadow $mainTransition;

                &:hover {
                    box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
                }
            }
        }
    }

    &-narrow {
        max-width: 754px;

        .account-box-title {
            margin-bottom: 2rem;
        }

        .form-control,
        .form-control-box,
        .btn-primary {
            width: 100%;

            @include media-breakpoint-up(sm) {
                max-width: 360px;
            }
        }

        .form-label {
            font-weight: 500;
            text-transform: uppercase;
        }

        a {
            text-decoration: none;
            font-weight: 500;
        }

        .notify {
            padding: 0.5rem 1rem;
            border: 1px solid #F2F4F6;
            box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.03);

            &-btn {
                width: 26px;
                height: 26px;
                flex: 0 0 26px;
                
                .btn-icon {
                    mask: url(../img/sprite.svg#include--notify-close-icon);
                    mask-repeat: no-repeat;
                    mask-position: center;
                    width: 12px;
                    height: 12px;
                }
            }

            &-text {
                font-weight: 500;
            }
        }
    }

    &-logged {
        .account-box {
            padding: 1rem;
            height: calc(100% - 1.375rem);

            @include media-breakpoint-up(md) {
                padding: 1.5rem;
            }

            &-title {
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.75rem;
                margin-bottom: 2.75rem;
            }
        }

        &-info {
            &-item {
                @include media-breakpoint-down(sm) {
                    > * {
                        &:first-child {
                            margin-right: 1rem;
                        }

                        &:last-child {
                            margin-left: auto;
                            flex: 0 0 60%;
                        }
                    }
                }

                & + & {
                    margin-top: 1.25rem;
                }
            }

            .btn-outline-primary {
                width: 100%;

                @include media-breakpoint-between(sm, lg) {
                    max-width: 240px;
                }
            }
        }
    }

    &-table {
        max-width: 545px;

        &-item {
            border-bottom: 1px solid #E8ECEF;
            padding: 0.25rem 0;

            @include media-breakpoint-down(sm) {
                flex-wrap: nowrap;
                overflow-x: auto;
            }

            > * {
                white-space: nowrap;
                
                @include media-breakpoint-down(sm) {
                    &:not(:last-child) {
                        margin-right: 1rem;
                    }

                    &:nth-child(2),
                    &:last-child {
                        margin-left: auto;
                    }

                    &:last-child {
                        text-align: right;
                        flex: 0 0 10%;
                    }
                }

                @include media-breakpoint-up(sm) {
                    flex: 0 0 33.3333%;
                    width: 33.3333%;

                    &.domain-name {
                        flex: 0 0 60%;
                        width: 60%;
                    }
                }
            }

            & + & {
                margin-top: 0.75rem;
            }
        }
    }

    &-newsletter {
        margin: 1.5rem 0;
    }

    &-forms {
        .btn-primary {
            width: 100%;
            max-width: 300px;
        }

        ul {
            padding-left: 1rem;
            margin: 1rem 0;
        }
    }
}

.filter-block {
    padding: 1rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
        padding: 1.875rem;
    }

    &-title {
        font-size: 1.125rem;
        margin-bottom: 1rem;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            margin-right: 3rem;
            margin-bottom: 0;
        }
    }

    &-params {

        select,
        select:focus {
            background-color: #F8FAFB;
            color: $primaryLightColor;

            &.promocode-category-select {
                max-width: 100%;
            }
        }

        select,
        input[type="text"] {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                max-width: 520px;

                &:not(:last-child) {
                    margin-right: 1.5rem;
                }
            }
        }

        .input-group {
            input[type="text"] {
                border-left-width: 0;
                padding-left: 0;

                &:focus {
                    border-color: #e8ecef;
                }
            }

            &-text {
                border: 1px solid #e8ecef;
                border-right-width: 0;
                background: transparent;
            }
        }
    }

    &-button {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 3rem;
        }

        .btn.small {
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}

.licenses-list-item {

    &>* {
        align-self: stretch;
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 1.25rem;

            &:not(:last-child) {
                border-right: $mainBorder;
            }
        }
    }

    &-image {
        @include media-breakpoint-down(md) {
            border-bottom: $mainBorder;
        }

        .btn.small {
            padding: 0.625rem 1.875rem;
        }
    }

    &-info {
        button[aria-label="Copy"] {
            position: relative;
            top: -2px;
        }

        &-top {
            .btn.staff-actions {
                background-color: #FCFCFC;
                width: auto;

                &:hover {
                    background-color: $primaryColor;

                    &:before {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
    }

    &-support {
        padding-left: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-down(md) {
            border-top: $mainBorder;
        }

        @include media-breakpoint-up(md) {
            align-self: center;
            min-width: 200px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}
