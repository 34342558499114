.column {
    padding: 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
    transition: box-shadow $mainTransition;

    &-title {
        font-weight: 500;
        text-transform: uppercase;
    }

    &-list-item {
        padding: 0.5rem 0.25rem;
        transition: background $mainTransition;
        border-bottom: 1px solid #F5F5F5;

        &:before {
            content: '';
            margin-right: 0.5rem;
            width: 28px;
            height: 28px;
        }

        &.blog-category-list-item:before {
            background: url(../img/sprite.svg#include--blog-list-icon) no-repeat center;
        }

        &.account-user-list-item:before {
            background: url(../img/sprite.svg#include--account-user-icon) no-repeat center;
            width: 28px;
            height: 18px;
        }

        &.account-licenses-list-item:before {
            background: url(../img/sprite.svg#include--account-licenses-icon) no-repeat center;
        }

        &.account-orders-list-item:before {
            background: url(../img/sprite.svg#include--account-orders-icon) no-repeat center;
        }

        &.account-subscribe-list-item:before {
            background: url(../img/sprite.svg#include--account-subscribe-icon) no-repeat center;
        }

        &.account-password-list-item:before {
            background: url(../img/sprite.svg#include--account-password-icon) no-repeat center;
        }

        &.account-logout-list-item:before {
            background: url(../img/sprite.svg#include--account-logout-icon) no-repeat center;
        }

        a {
            text-decoration: underline;
            display: block;
            width: 100%;
            height: 100%;
        }

        &:hover {
            background: #FCFCFC;

            a {
                text-decoration: none;
                color: $linksColor;
            }
        }
    }

    & + & {
        margin-top: 1rem;

        @include media-breakpoint-up(lg) {
            margin-top: 1.5rem;
        }
    }

    &:hover {
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05)
    }
}