.modal {
    &-dialog {
        max-width: 520px;

        &.narrow {
            max-width: 500px;
        }

        &-review {
            max-width: 700px;
        }
    }

    &-header {
        padding: 2.5rem 2.5rem 0;
        text-align: center;
        border-bottom: 0;

        @include media-breakpoint-up(sm) {
            padding: 2.5rem 5rem 0;
        }

        h3 {
            font-size: 1.625rem;
        }

        .btn-close {
            background-color: #f8fafb;
            background-image: url(../img/sprite.svg#include--notify-close-icon);
            border-radius: 0.375rem;
            opacity: 1;
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            margin: 0;
            transition: opacity $mainTransition;
            
            &:hover {
                opacity: 0.95;
            }
        }
    }

    &-body {
        padding: 1.5rem 1.5rem 2.5rem;

        @include media-breakpoint-up(sm) {
            padding: 1.5rem 5rem 2.5rem;
        }

        p {
            color: $lightTextColor;
        }

        .blue-link.fw-500 {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        form {
            &  > * {
                &:not(:last-child) {
                    margin-bottom: 1.5rem;

                    @include media-breakpoint-up(sm) {
                        margin-bottom: 2.5rem;
                    }
                }
            }

            p {
                max-width: 360px;
            }
        }

        .form-label {
            text-transform: uppercase;
        }

        &-success {
            @include media-breakpoint-up(sm) {
                padding: 1.5rem 4rem 2.5rem;
            }

            & > * {
                &:not(:last-child) {
                    margin-bottom: 1.5rem;

                    @include media-breakpoint-up(sm) {
                        margin-bottom: 2.5rem;
                    }
                }
            }

            p {
                max-width: 100%;
            }
        }
    }

    &-cart {
        border-top: 1px solid #E8ECEF;
        border-bottom: 1px solid #E8ECEF;
        max-height: 206px;
        overflow-x: auto;

        @include media-breakpoint-up(sm) {
            max-height: 412px;
        }

        &-item {
            & + & {
                border-top: 1px solid #E8ECEF;
            }

            &-delete {
                border: 1px solid #F8F8F8;
                padding-left: 2rem;
                padding-right: 2rem;

                .btn-icon {
                    mask-image: url(../img/sprite.svg#include--delete-cart-icon);
                    width: 20px;
                    height: 20px;
                    background: #FE4D97;
                }

                .btn-text {
                    color: #FE4D97;
                }

                &:hover {
                    background: #FE4D97;
                    border-color: #FE4D97;

                    .btn-text {
                        color: #FFFFFF;
                    }

                    .btn-icon {
                        background: #FFFFFF;
                    }
                }
            }
        }
    }
}
