.btn {
    padding: 0.938rem 5rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
        width: 100%;
        justify-content: center;
    }

    &-icon {
        mask-size: cover;
        mask-repeat: no-repeat;
        transition: background $mainTransition;
    }

    &-text {
        transition: color $mainTransition;
    }

    &.small {
        padding: 0.625rem 1rem;
        width: auto;
    }

    &.with-dropdown {
        &::after {
            content: '';
            mask: url(../img/sprite.svg#include--btn-dropdown-icon);
            mask-repeat: no-repeat;
            mask-size: cover;
            width: 12px;
            height: 10px;
            margin-left: auto;
            background: $primaryLightColor;
            transition: background $mainTransition, transform $mainTransition;
        }

        &:hover::after {
            background: #FFFFFF;
        }

        &.clicked,
        &:focus {
            background: $primaryLightColor;

            .btn-text {
                color: #FFFFFF;
            }

            &::after {
                background: #FFFFFF;
            }
            
        }

        &.show,
        &.clicked {
            &::after {
                transform: rotate(180deg);
                background: #FFFFFF;
            }
        }
    }

    &-danger,
    &-primary,
    &-secondary {
        border: 0;
    }

    &-primary {
        background: $primaryColor;

        &:hover {
            background: #4973EA;
        }
    }

    &-secondary {
        background: #F2F4F6;
        color: $primaryLightColor;

        .btn-icon {
            background: $primaryLightColor;
        }

        .btn-text {
            color: #28293D;
        }

        &:hover,
        &.show {
            background: $primaryLightColor;

            .btn-text {
                color: #FFFFFF;
            }

            .btn-icon {
                background: #FFFFFF;
            }
        }
    }

    &-danger {
        background: $redColor;

        &:hover {
            background: #e54354;
        }
    }

    &-outline {
        &-primary {
            color: $primaryColor;
            border-color: $primaryColor;

            &:hover {
                background: $primaryColor;
            }
        }

        &-secondary {
            color: $primaryLightColor;
            border-color: $primaryLightColor;

            &:hover {
                background: $primaryLightColor;
                border-color: $primaryLightColor;
            }
        }
    }

    &.loading {
        position: relative;
        overflow: hidden;
        cursor: wait;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 150%;
            height: 100%;
            background: repeating-linear-gradient(60deg,
                transparent,
                transparent .75rem,
                hsla(231, 44%, 51%, 0.4) .75rem,
                hsla(231, 44%, 51%, 0.4) 1.5rem);
            animation: load 1s infinite linear;
        }
    }
}

@keyframes load {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-1.75rem);
    }
}

.showmore {
    padding: 1rem 0;

    @include media-breakpoint-up(lg) {
        padding: 2rem 0 1rem;
    }
}

.staff-actions {
    padding: 0.5rem 0.375rem;
    border-radius: 1px;
    background: #FCFCFC;
    transition: background $mainTransition;

    &:before {
        content: '';
        display: block;
        background-image: url("../../img/sprite.svg#include--actions-icon");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 22px;
        height: 4px;
    }

    &:hover,
    &.show {
        background-color: $primaryColor;

        &:before {
            filter: brightness(0) invert(1);
        }
    }
}