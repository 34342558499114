.hero {
    background: #FFFFFF;
    position: relative;

    .col-12 {
        position: relative;
        z-index: 2;
    }

    &-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &-blur {
            position: absolute;
            opacity: 0.9;
            filter: blur(300px);
            border-radius: 100%;
            z-index: 1;

            &-first {
                background: rgba(147, 188, 236, 0.15);

                height: 359px;
                width: 100%;
                left: 0;
                right: 0;
                top: 6px;

                @include media-breakpoint-up(md) {
                    height: 353px;
                    width: 243px;
                    top: 5px;
                    right: auto;
                }

                @include media-breakpoint-up(lg) {
                    width: 353px;
                }


                @include media-breakpoint-up(xl) {
                    height: 508px;
                    width: 506px;
                    left: -118px;
                    top: -49px;
                }
            }

            &-second {
                background: rgba(181, 207, 248, 0.64);

                height: 253px;
                width: 288px;
                left: 0;
                top: 183px;

                @include media-breakpoint-up(md) {
                    height: 248px;
                    width: 171px;
                    left: 442px;
                    top: 180px;
                }

                @include media-breakpoint-up(lg) {
                    width: 248px;
                    left: auto;
                    right: 200px;
                }

                @include media-breakpoint-up(xl) {
                    height: 357px;
                    width: 356px;
                    top: 202px;
                }
            }

            &-third {
                background: rgba(214, 224, 242, 0.64);

                height: 253px;
                width: 245px;
                left: 115px;
                top: 0;

                @include media-breakpoint-up(md) {
                    height: 248px;
                    width: 171px;
                    left: 597px;
                }

                @include media-breakpoint-up(lg) {
                    width: 248px;
                    left: auto;
                    right: 0;
                }


                @include media-breakpoint-up(xl) {
                    height: 357px;
                    width: 356px;
                    top: -57px;
                }
            }
        }

    }

    &-text {

        &-title {
            margin-bottom: 1rem;

            @include media-breakpoint-between(md, xl) {
                margin-bottom: 2rem;
            }
        }

        @include media-breakpoint-between(md, xl) {
            flex: 0 0 60%;
            width: 60%;
        }
    }

    h1 {
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 1.625rem;
        margin-bottom: 1.5rem;
        padding-top: 2rem;

        @include media-breakpoint-up(md) {
            font-size: 1.625rem;
            line-height: 1.875rem;
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.875rem;
            line-height: 2.25rem;
            margin-bottom: 4rem;
            max-width: 22.25rem;
            padding-top: 6.25rem;
        }
    }

    ul {
        font-weight: 300;
        font-size: 0.875rem;
        line-height: 1.375rem;
        margin-left: 1.5rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.125rem;
            line-height: 1.625rem;
            margin-left: 1rem;
        }
    }
    
    &-btn {

        .btn {
            margin: 2rem 0;
            justify-content: center;
            padding-top: 1rem;
            padding-bottom: 1rem;

            @include media-breakpoint-between(sm, md) {
                margin-left: auto;
                margin-right: auto;
            }

            @include media-breakpoint-up(md) {
                padding: 1rem 2rem;
            }

            @include media-breakpoint-up(xl) {
                position: absolute;
                top: -10rem;
                padding: 1.5rem 3rem;
            }
        }
    }

    &-image {
        @include media-breakpoint-up(xl) {
            position: relative;
            top: -5rem;
        }

        img {
            @include media-breakpoint-down(md) {
                margin-top: 2.5rem;
            }
        }

    }
}

.benefits {
    padding-bottom: 4.375rem;

    &-item {
        background: #FFFFFF;
        padding: 1.5rem;
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        margin-bottom: 1.875rem;
        height: calc(100% - 1.875rem);
        transition: box-shadow $mainTransition;

        &:hover {
            box-shadow: 0px 2px 16px 0px #999BA81F;
        }

        &-icon {
            background: #F8FAFB;
            padding: 0.375rem;
        }

        &-title {
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.375rem;
            letter-spacing: 0.2px;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
        }

        &-text {
            font-weight: 300;
            
            @include media-breakpoint-up(xl) {
                max-width: 90%;
            }

            @include media-breakpoint-up(xl) {
                max-width: 80%;
            }
        }
    }
}

.faq {
    background: #FFFFFF;
    padding-bottom: 3.25rem;

    &-item {
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        cursor: pointer;
        margin-bottom: 1.75rem;

        * {
            pointer-events: none;
        }

        &-title {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.25rem;

            @include media-breakpoint-up(lg) {
                font-size: 1.125rem;
                line-height: 1.375rem;
            }
        }

        &-btn {
            background-color: #F8FAFB;
            background-image: url(../img/sprite.svg#include--faq-plus-icon);
            background-size: 8px 8px;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50px;
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
            transition: background-color $mainTransition;

            @include media-breakpoint-up(md) {
                width: 40px;
                height: 40px;
                flex: 0 0 40px;
                background-size: 16px 16px;
            }
        }

        &-text {
            @include media-breakpoint-down(md) {
                font-size: 0.875rem;
            }
            padding: 0 1.5rem;
            font-weight: 300;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: opacity $mainTransition,
            max-height $mainTransition,
            padding-bottom $mainTransition;

            p + p {
                margin-top: 1rem;
            }
        }

        &:hover {
            .faq-item-title {
                text-decoration: underline;
            }

            .faq-item-btn {
                background-color: $primaryColor;
                background-image: url(../img/sprite.svg#include--faq-plus-icon-white);
            }
        }

        &.active {
            .faq-item-btn {
                background-color: $primaryColor;
                background-image: url(../img/sprite.svg#include--faq-minus-icon);
            }

            .faq-item-text {
                opacity: 1;
                max-height: 400px;
                padding-bottom: 1.5rem;
            }
        }
    }
}

.clients {
    background: #FFFFFF;
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 4rem;
    }

    &-item {
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        margin-bottom: 1rem;
        transition: box-shadow $mainTransition;

        &:hover {
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);

            a {
                color: $linksColor;
            }
        }

        &-img {
            padding: 2.625rem 0;
        }

        &-link {
            padding: 1rem;
            background: #F4F7FA;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.reviews {
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 4rem;
    }

    &-item {
        background: #FFFFFF;
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        margin-bottom: 1rem;
        padding: 1.5rem;
        transition: box-shadow $mainTransition;
        height: calc(100% - 1rem);

        @include media-breakpoint-up(lg) {
            margin-bottom: 2.25rem;
            height: calc(100% - 2.25rem);
        }

        &-img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            margin-right: 1.25rem;
            background: #E8ECEF;
        }

        &-author {
            font-weight: 500;
            text-transform: uppercase;
        }

        &-date,
        &-text {
            font-weight: 300;
        }

        &-title {
            text-decoration: underline;
            margin: 1.5rem 0 1rem;

            &:hover {
                text-decoration: none;
            }
        }

        &-text {
            margin-bottom: 1.5rem;
        }

        &:hover {
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);

            .reviews-item-title {
                color: $linksColor;
            }
        }
    }
}

.feedback {
    display: grid;
    background: radial-gradient(90.28% 244.51% at 95.14% 11.09%, rgba(31, 40, 71, 0.9) 0%, rgba(5, 22, 68, 0.837) 100%);
    
    @include media-breakpoint-up(sm) {
        height: 875px;
        overflow: hidden;
    }

    > * {
        grid-area: 1/-1;
    }

    img {
        width: 100%;
        height: 100%;
        min-height: 0;
        object-fit: cover;
    }

    &:after {
        content: "";
        background: rgba(255, 255, 255, 0.03);
        grid-area: 1/-1;
        filter: blur(10px);
    }

    &-form {
        z-index: 1;
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        width: 100%;
        max-width: 560px;
        background: #FFFFFF;
        padding: 2rem 1rem;
        

        @include media-breakpoint-up(sm) {
            margin-top: 5rem;
            margin-bottom: 5rem;
            border-radius: 4px;
        }

        @include media-breakpoint-up(sm) {
            padding: 3rem 6.125rem;
        }

        &-title {
            font-weight: 500;
            font-size: 1.875rem;
            line-height: 2.25rem;
            margin-bottom: 0.75rem;
        }

        &-text {
            margin-bottom: 2rem;
            font-size: 0.875rem;

            @include media-breakpoint-up(sm) {
                font-size: 1.125rem;
            }
        }
    }
}

.news {
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 4rem;
    }

    &-item {
        background: #FFFFFF;
        box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        margin-bottom: 1rem;
        padding: 1.5rem;
        transition: box-shadow $mainTransition;
        height: calc(100% - 1rem);

        @include media-breakpoint-up(lg) {
            margin-bottom: 2.25rem;
            height: calc(100% - 2.25rem);
        }

        &-title {
            text-decoration: underline;
            margin-bottom: 0.75rem;
            font-weight: 700;

            &:hover {
                text-decoration: none;
            }
        }

        &-text {
            margin: 1rem 0 1.5rem;
            font-weight: 300;
            word-break: break-word;
        }

        &:hover {
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);

            .news-item-title {
                color: $linksColor;
            }
        }
    }
}