.footer {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .with-dropdown {
        text-transform: uppercase;
        min-width: 220px;
        max-width: 100%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .btn-text {
            font-weight: 500;
        }
    }

    &-list {
        font-size: 0.75rem;

        @include media-breakpoint-up(xl) {
            font-size: 1rem;

            li:not(:last-child) {
                margin-right: 1.5rem;
            }
        }

        @include media-breakpoint-down(md) {
            max-height: 0;
            overflow: hidden;
            transition: max-height $mainTransition, padding $mainTransition;

            &.active {
                padding: 1rem 0;
                max-height: 600px;
            }

            .list-inline-item {
                display: block;
                margin-right: 0;
                font-size: 1rem;
                padding-left: 1rem;

                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $linksColor;
                text-decoration: none;
            }
        }
    }

    &-payments {
        gap: 1rem;

        &-item {
            padding: 0.5rem;
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
            transition: box-shadow $mainTransition;

            &:hover {
                box-shadow: 0px 2px 20px rgba(37, 38, 49, 0.05);
            }
        }
    }

    &-copyrights {
        font-weight: 300;
        border-color: #F8F8F8 !important;
    }
}