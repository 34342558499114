.product {
    &-top {
        gap: 1rem;

        @include media-breakpoint-down(lg) {
            margin-left: -0.75rem;
            margin-right: -0.75rem;
        }
        
        @include media-breakpoint-up(lg) {
            gap: 1.875rem;
        }
    }

    &-box {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 calc(50% - 0.875rem);
            width: calc(50% - 0.875rem);
        }

        &.product-info {
            padding: 0;

            .product-info-wrapper {
                padding: 1rem;

                @include media-breakpoint-up(md) {
                    padding: 1.5rem;
                }
            }
        }

        .withdrawn {
            padding: 1rem;

            @include media-breakpoint-up(md) {
                padding: 1.5rem;
            }
        }
    }

    &-benefits {
        padding-top: 1rem;
        gap: 1rem;

        @include media-breakpoint-up(md) {
            padding-top: 1.5rem;
            gap: 1.875rem;
        }

        &-item {
            padding: 0.75rem;
            background: #F8FAFB;
            border: 1px solid #EAF1F4;
            flex: 0 0 100%;
            width: 100%;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                flex: 0 0 calc(50% - 1rem);
                width: calc(50% - 1rem);
            }

            &:hover {
                color: $linksColor;

                span {
                    text-decoration: underline;
                }
            }
        }

        #presentationTrigger {
            cursor: pointer;
        }

        .modal-fullscreen {
            max-width: none;

            .modal-header {
                padding: 1.25rem;
            }
        }

        #presentationModalBody {
            padding: 0;

            
        }

        @include media-breakpoint-down(sm) {
            #presentationModal {
                .modal-header h3 {
                    font-size: 1rem;
                    padding-right: 2.25rem;
                }
            }
        }
    }

    &-price-text {
        color: #5A5B63;
        font-weight: 500;
    }

    &-rating #reviewsTrigger {
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    &-specifications {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
            margin-top: 1.5rem;
        }

        &-item {
            > {
                * {
                     flex: 0 0 50%;
                     width: 50%;
                }

                span:last-child {
                    text-align: left;
                    padding-left: 1rem;
                }
            }
        }
    }

    &-maintext {
        font-weight: 300;
        margin-top: 1rem;

        p + p {
            margin-top: 1rem;
        }
    }

    &-buy {
        position: relative;
        padding: 1rem;
        border-top: 1px solid #F7F7F7;
        background: #F8FAFB;
        border-radius: 0px 0px 0.5rem 0.5rem;
        margin-top: auto;

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
        }

        &-title {
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .popover-btn {
            height: 1.25rem;
            flex: 0 0 1.25rem;
            width: 1.25rem;
            border-radius: 50%;
        }

        .btn-cart {
            padding-top: 0.875rem;
            padding-bottom: 0.875rem;
        }

        .form-label {
            display: flex;
            align-items: center;
        }
    }

    &-tabs {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            margin-left: -0.75rem;
            margin-right: -0.75rem;
        }

        @include media-breakpoint-up(md) {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 1.875rem;
            margin-bottom: 1.875rem;
        }

        .nav-tabs {
            border-bottom: 0;
            overflow-x: auto;

            .badge {
                padding: 0.125rem 0.25rem;
                font-size: 0.625rem;
                line-height: 0.75rem;
                color: #FFFFFF;
                background: $primaryColor;
            }
        }

        .nav-item {
            background: #FEFEFE;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                flex: 0 0 200px;
                max-width: 200px;
            }

            &:not(:first-child) {
                border-left: 1px solid #F7F7F7;
            }

            &:last-child {
                border-top-right-radius: 0.5rem;
            }
        }

        .nav-tabs .nav-link {
            border-radius: 0;
            margin-bottom: 0;
            border-bottom: 1px solid #F7F7F7;
            padding: 1.25rem 2.5rem;
            text-transform: uppercase;
            color: $primaryLightColor;
            text-decoration: underline;
            border-top: 2px solid transparent;
            white-space: nowrap;
            
            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &:focus,
            &:hover {
                border-color: transparent;
                border-bottom: 1px solid #F7F7F7;
                color: $primaryDarkColor;
            }
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background-color: transparent;
            border-color: transparent;
            color: $primaryDarkColor;
            text-decoration: none;
            border-top-color: $primaryColor;
        }

        .tab-content {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
            border-radius: 0px 0 0.5rem 0.5rem;
            padding: 1rem;
            overflow: hidden;

            @include media-breakpoint-up(lg) {
                padding: 1.5rem;
                border-radius: 0px 0.5rem 0.5rem 0.5rem;
            }
        }

        .tab-pane {
            > * + * {
                margin-top: 1rem;
            }

            ul,
            ol {
                margin-left: 1rem;
            }

            p,
            li {
                font-weight: 300;
            }

            b {
                font-weight: 500;
            }

            img {
                max-width: 100%;
            }

            a {
                color: $linksColor;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &-title {
                &-text,
                &-badge {
                    letter-spacing: 0.5px;
                }

                &-text {
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                }

                &-badge {
                    padding: 0.5rem 0.75rem;
                    background: #F8FAFB;
                    border: 1px solid #F2F4F6;
                    line-height: 17px;
                    margin-left: 0.75rem;
                }

                & + button {
                    padding-top: 0.875rem;
                    padding-bottom: 0.875rem;
                    line-height: 1rem;
                }
            }
        }
    }

    &-comment {
        border: 1px solid #F6F6F6;

        & + & {
            margin-top: 1rem;
        }

        &-text {
            margin-top: 1rem;

            @include media-breakpoint-down(md) {
                font-size: 0.875rem;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 1.5rem;
            }

            p + p {
                margin-top: 1rem;
            }

            &-admin {
                padding: 1rem;
                background: #F8FAFB;

                &-title {
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}