* {
	outline: none;
}

@include media-breakpoint-down(lg) {
	html {
	    height: 100%;
	}
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

ul, li {
	padding: 0;
}

a, a:hover, a:active {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	@include media-breakpoint-up(lg) {
		transition: color $mainTransition;
	}
}

button {
	cursor: pointer;
	border: 0;
}

@supports (-webkit-touch-callout: none) {
	.form-control,
	input[type=text] {
		@include media-breakpoint-down(md) {
			font-size: 16px !important;
		}
	}
}

div, p, form, input, a, span, button {
	box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0;
}

textarea, input[type="text"], input[type="email"] {
	-webkit-appearance: none;
}

select::-ms-expand {
    display: none;
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
