.services {

    @include media-breakpoint-up(xl) {
        padding-bottom: 4rem;
    }

    &-item {
        padding: 1rem;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
        transition: box-shadow $mainTransition;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
            margin-bottom: 1.625rem;
        }

        &-title {
            font-weight: 500;
            margin-bottom: 1rem;

            @include media-breakpoint-up(md) {
                font-weight: 700;
                margin-bottom: 1.75rem;
            }
        }

        &-price {
            font-weight: 500;
            font-size: 1.25rem;
            margin: 1.25rem auto;

            @include media-breakpoint-up(md) {
                margin: 0 0 auto;
            }
        }

        @include media-breakpoint-down(md) {
            .btn {
                width: 100%;
            }
        }

        .btn-outline-primary {
            padding: 0.75rem 2rem;
        }

        .btn-secondary {
            .btn-text {
                font-weight: 500;
                text-decoration: underline;
            }

            &.clicked,
            &:hover {
                .btn-text {
                    text-decoration: none;
                }
            }
        }

        &-text {
            max-height: 0;
            overflow: hidden;
            transition: max-height $mainTransition;

            &.active {
                max-height: 700px;
            }

            p {
                margin-top: 1.5rem;
            }

            a {
                color: $linksColor;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &:hover {
            box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
        }
    }
}