.blog {
    &-category {
        &-item {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
            transition: box-shadow $mainTransition;
            padding: 1rem;

            @include media-breakpoint-up(md) {
                padding: 1.5rem;
            }

            &-title {
                font-weight: 700;
                line-height: 1.25rem;
            }

            &-text,
            &-date {
                font-weight: 300;
            }

            &-text {
                @include word-wrap;
            }

            & + & {
                margin-top: 1rem;

                @include media-breakpoint-up(lg) {
                    margin-top: 1.5rem;
                }
            }

            &:hover {
                box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);

                .blog-category-item-title {
                    color: $linksColor;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &-page {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }

        @include media-breakpoint-down(sm) {
            section + aside {
                margin-top: 1.5rem;
            }
        }

        &-item {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
            transition: box-shadow $mainTransition;
            padding: 1rem;
            font-weight: 300;

            @include media-breakpoint-up(md) {
                padding: 1.5rem;
            }

            ul {
                padding-left: 1rem;
                margin-bottom: 1rem;
            }

            blockquote {
                margin-bottom: 1rem;
                background: #1B2D61;
                color: #FFFFFF;
                font-weight: 400;
                padding: 1rem;
                @include word-wrap;

                ul {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 0;
                }
            }

            p + p {
                margin-top: 1rem;
            }

            a {
                color: $linksColor;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            h2 {
                margin-bottom: 2rem;
            }
        }

        &-info {
            &-item {
                & + & {
                    margin-top: 1rem;
                }

                span {
                    &:last-child {
                        font-weight: 300;
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}