.simple-content {
    h3 {
        margin-bottom: 1rem;
    }

    .table {
        >:not(:first-child) {
            border: 0;
        }

        >:not(caption)>*>* {
            padding: 1rem 0.5rem;
            background-color: transparent;
            border-bottom-width: 1px;
            border-color: #e8ecef;
        }

        .total {
            text-align: right;
        }
    }

    .table-hover>tbody>tr {
        transition: background $mainTransition;

        &:hover>* {
            --bs-table-accent-bg: #f1f4f6;
        }
    }

    .simplecheckout-cart-total {
        text-align: right;

        &.box2 {
            margin-bottom: 1rem;
        }
    }

    #input-cupon {
        max-width: 150px;
        margin-left: 1rem;
    }

    #simplecheckout_customer {
        border-top: 1px solid #e8ecef;
        border-bottom: 1px solid #e8ecef;
        padding: 1rem 0;
        margin: 1.5rem 0 1rem;
    }

    .simplecheckout-table-form {
        margin-bottom: 0;

        td {
            border: 0;
            padding: 0.5rem 0;
        }
    }

    #simplecheckout_payment {
        .form-group {
            display: flex;
            align-items: center;
        }

        input[type="radio"] {
            margin: 0 1rem 0 0.25rem;
        }

        .simplecheckout-block-content {
            margin-bottom: 0.5rem;
        }
    }

    .simplecheckout-error-text,
    .simplecheckout-warning-block {
        color: $redColor;
    }

    .form-control {
        max-width: 500px;
    }

    .simplecheckout-table-form-left {
        @include media-breakpoint-up(md) {
            width: 200px;
        }
    }
}

#modal-agree {
    .modal-dialog {
        max-width: 800px;
    }

    .modal-body {
        p {
            color: $darkTextColor;
        }

        h2 {
            margin-bottom: 1rem;
        }
    }
}