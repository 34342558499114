.sidebar {
    position: fixed;
    z-index: 10023;
    top: 0;
    bottom: 0;
    left: -100%;
    max-height: 100vh;
    width: 100%;
    max-width: 414px;
    transition: transform .5s ease, box-shadow .5s ease;
    box-shadow: 20px 0 20px -8px rgba(0, 0, 0, 0);
    overflow: hidden;

    @media (min-width: 414px) {
        left: -414px;
    }

    &.active {
        box-shadow: 0 0 20px -8px rgba(0, 0, 0, .2);
        transform: translateX(100%)
    }

    &-inner {
        background: #FFFFFF;
        height: 100%;
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    &-close {
        background-color: #F8FAFB;
        background-image: url(../img/sprite.svg#include--notify-close-icon);
        background-size: 21px 21px;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0.5rem;
        width: 2.5em;
        height: 2.5em;
        box-shadow: 0px 2px 100px rgba(37, 38, 49, 0.8);
    }

    &-content {

        &-block {
            background: #FFFFFF;
            box-shadow: 0px 2px 100px rgba(37, 38, 49, 0.1);

            li + li {
                margin-top: 1.25rem;
            }

            & + & {
                margin-top: 1.5rem;
            }
        }
    }
}

#mobile-currency {
    margin: 1.5rem 0;

    button[data-bs-toggle="dropdown"] {
        display: none;
    }

    .dropdown-menu {
        position: relative !important;
        display: block !important;
        right: 0 !important;
        top: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
    }
}