.form-label {
    font-size: 0.875rem;
}

.form-control {
    color: $darkTextColor;
    font-size: 0.875rem;
    background-color: #FFFFFF;
    border: 1px solid #E8ECEF;
    padding: 0.907rem 1rem;
    border-radius: 0.25rem;

    @include media-breakpoint-down(lg) {
        transition: unset;
    }

    &::placeholder {
        color: $lightTextColor;
        font-size: 0.875rem;
    }

    &:focus {
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.02);
        border: 1px solid #bccdf8;
    }
}

.form-select {
    cursor: pointer;
    background-image: url(../img/sprite.svg#include--form-select);

    &:disabled {
        background-color: #F8FAFB;
        color: $lightTextColor;
    }
}

.form-check {

    &-input {
        flex: 0 0 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.75rem;
        margin-top: 0;
        border-color: $lightTextColor;

        &[type=checkbox] {
            border-radius: 0.125rem !important;
        }

        &:checked {
            background-color: $primaryColor;
            border-color: $primaryColor;
        }
    }

    &-label {
        font-size: 0.875rem;

        a {
            color: $linksColor;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}



textarea.form-control {
    transition: unset;
    min-height: 250px;

    &:focus {
        border: $mainBorder !important;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #F8FAFB;
}
