*,
*::after,
*::before {
	box-sizing: border-box;
}

html {
	font-size: 16px;
}

body,
html {
	min-height: 100vh;
	position: relative;
}

body {
	font-size: 1rem;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	background: #F8FAFB;
	color: $darkTextColor;
	display: flex;
	flex-direction: column;
}

.h1,
h1 {
	font-size: 1.875rem;
}

.h2,
h2 {
	font-size: 1.625rem;
}

.h3,
h3 {
	font-size: 1.25rem;
}

.h4,
h4 {
	font-size: 1.125rem;
}

.h5,
h5 {
	font-size: 1rem;
}

.h6,
h6 {
	font-size: 0.875rem;
}

hr {
	color: #F2F4F6;
	opacity: 1;
}

.section-title {
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 2rem 0;

	@include media-breakpoint-down(md) {
		text-align: center;
	}

	@include media-breakpoint-up(lg) {
		padding: 5rem 0 4.5rem;
	}

	span {
		border-bottom: 4px solid $primaryColor;
	}
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	@media (min-width: 1441px) {
		max-width: 1440px;
	}
}

.red,
.required {
	color: $redColor !important;
}

.green-text {
	color: $greenColor;
}

.blue-link {
	color: $linksColor;
	text-decoration: underline;
	cursor: pointer;
	background: none;
	padding: 0;

	&:hover {
		text-decoration: none;
		color: $linksColor;
	}
}

.bg-secondary {
	background-color: $primaryLightColor !important;
}

.primary-dark {
	color: $primaryDarkColor;
}

.border-bottom {
	border-bottom: $mainBorder!important;
}

.light-text {
	color: $lightTextColor !important;
}

.fw-300 {
	font-weight: 300;
}

.fw-500 {
	font-weight: 500;
}

.fsz-10 {
	font-size: 0.625rem !important;
}

.fsz-11 {
	font-size: 0.688rem !important;
}

.fsz-12 {
	font-size: 0.75rem !important;
}

.fsz-13 {
	font-size: 0.813rem !important;
}

.fsz-14 {
	font-size: 0.875rem !important;
}

.fsz-18 {
	font-size: 1.125rem !important;
}

.br-4 {
	border-radius: 0.25rem;
}

.br-6 {
	border-radius: 0.375rem;
}

.br-8 {
	border-radius: 0.5rem;
}

.error_style {
	background-color: #FFEEF5 !important;
	border: 1px solid #FE4D97 !important;
	transition: background-color $mainTransition, border $mainTransition;

	& + label {
		color: $redColor !important;
	}
}

form[data-form="send"] {
	position: relative;

	.status {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #FFFFFF;
		margin-bottom: 0 !important;

		p + p {
			margin-top: 1rem;
		}
	}
}

.badge {
	font-weight: 400;
}

.content-block {
	background: #FFFFFF;
	border-radius: 0.25rem;
	padding: 1rem;
	@include media-breakpoint-up(xxl) {
		padding: 1.875rem;
	}

	p + p,
	& + & {
		margin-top: 1rem;
	}

	ul {
		padding-left: 1rem;
	}
}

.pagination {
	.page-item {

		&.active,
		&:hover {
			.page-link {
				background-color: $primaryColor;
				border-color: $primaryColor;
				color: #FFFFFF;
			}
		}

		&:not(:first-child) {
			margin-left: 0.25rem;
		}

		&-prev,
		&-next {
			display: none;
		}
	}

	.page-link {
		border: 1px solid #E8ECEF;
		border-radius: 0.25rem;
		padding: 0.5rem 1rem;
		color: $primaryLightColor;
		text-decoration: none !important;
	}
}

.breadcrumb {
	background: transparent;
	padding-top: 1.5rem;
	padding-bottom: 0.5rem;
	font-weight: 300;
	flex-wrap: nowrap;
	overflow-x: auto;

	&-item {
		white-space: nowrap;
		display: inherit;

		& + & {
			padding-left: 0.625rem;

			&::before {
				content: '/';
				color: $lightTextColor;
			}
		}
	}

	&.active {
		color: $lightTextColor;
	}

	a {
		color: $linksColor;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.page-container {
	h1 {
		font-size: 1.5rem;
		line-height: 1.75rem;
		font-weight: 700;
		margin-bottom: 1rem;

		@include media-breakpoint-up(md) {
			font-size: 1.875rem;
			line-height: 2.25rem;
			margin-bottom: 1.5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 2rem;
		}
	}
}

// Dropdown

.dropdown {
	&-toggle:after {
		display: none;
	}

	&-menu {
		border: 0;
		box-shadow: 0px 2px 100px rgba(37, 38, 49, 0.1);
		width: 18rem;

		@include media-breakpoint-up(xl) {
			width: 21rem;
		}

		&:after {
			content: "";
	    	position: absolute;
			right: 10%;
	    	top: -20px;
	    	border: 10px solid transparent;
	    	border-top: 10px solid #fff;
			transform: rotate(180deg);
		}
	}

	&-item,
	&-checkbox {
		padding: 1rem 0;
	}

	&-header {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	&-divider {
		border-color: #E8ECEF;
		height: 0 !important;
	}

	&-item {
		&:focus,
		&:hover,
		&.active {
			background: transparent;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}

@keyframes slideIn {
	0% {
		transform: translateY(1.5rem);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.slideInDropdown {
	-webkit-animation-name: slideIn;
	animation-name: slideIn;
	animation-duration: 0.4s;
	animation-fill-mode: both;
}

.popover {
	border-color: transparent;
	border-radius: 0.5rem;
	box-shadow: 0px 2px 100px rgba(37, 38, 49, 0.1);

	@include media-breakpoint-up(md) {
		min-width: 380px;
	}

	&-arrow {
		&:before {
			border-color: transparent !important;
		}
	}

	&-header {
		border-bottom: 0;
		background-color: transparent;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 1.75rem;
		padding: 1.5rem;

		&:before {
			border-color: transparent !important;
		}
	}

	&-body {
		padding: 0 1.5rem 1.5rem;
		font-weight: 300;
		font-size: 0.875rem;

		p + p {
			margin-top: 1rem;
		}
	}
}

.item-box {
	display: none
}

.alert-toast {
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 10000;
	@include media-breakpoint-down(sm) {
		left: 1rem;
	}

	> *:not(:first-child){
		margin-top: 1rem;
	}

	.toast {
		background: #FFFFFF;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		&-body {
			padding: 1.5rem 0.75rem;
		}

		&-inner:before {
			content: '';
			display: inline-block;
			width: 24px;
			height: 24px;
			align-self: center;
			background-size: 24px;
			flex: 0 0 24px;
			margin-left: 1.5rem;
		}

		&-danger {
			.toast-inner:before {
				background: url(../img/sprite.svg#include--alert-danger-icon) no-repeat;
			}
		}

		&-warning {
			.toast-inner:before {
				background: url(../img/sprite.svg#include--alert-warning-icon) no-repeat;
			}
		}

		&-success {
			.toast-inner:before {
				background: url(../img/sprite.svg#include--alert-succes-icon) no-repeat;
			}
		}
	}
}

#oct-policy {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10000;
	background: #FFFFFF;

	a {
		color: $linksColor;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.btn {
		font-weight: 500;
	}

	&.clicked {
		display: none;
	}
}

.g-recaptcha > div {
	margin: 0 auto;
}

.scrollToTopBtn {
	position: fixed;
	z-index: 1100;
	width: 60px;
	height: 60px;
	border: $mainBorder;
	border-radius: 50%;
	bottom: 100px;
	right: 24px;
	display: block;
	padding: 0.5rem 0.25rem;
	cursor: pointer;
	transform: translateY(100px) rotate(180deg);
	opacity: 0;
	transition: transform 0.5s, opacity 0.3s;
	background: #FFFFFF url(../img/sprite.svg#include--form-select) no-repeat center;
	background-size: 24px;

	&.showScrollBtn {
		transform: translateY(0) rotate(180deg);
		opacity: 0.7;

		&:hover {
			opacity: 1;
		}
	}
}

.images-preview {
	max-width: 750px;
	margin: 1rem auto;
	border: #fafafa solid 12px;
	background-color: #fafafa;
	border-radius: 8px;

	img {
		width: 100%;
		height: auto;
	}

	&-thumbs {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 12px;

		img {
			cursor: pointer;
		}
	}
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

.fade-in {
	opacity: 0;
	animation: fadeIn 0.5s ease-in 1 forwards;
}