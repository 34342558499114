.header {
    background: #FFFFFF;
    padding: 0.625rem 0;
    border-bottom: 1px solid #E8ECEF;
    box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);

    &-left {
        nav {
            margin-left: 3.25rem;
            font-size: 0.875rem;
            font-weight: 500;

            a:hover {
                text-decoration: underline;
                color: $linksColor;
            }
        }

        .list-inline-item:not(:last-child) {
            margin-right: 1rem;
        }
    }

    #logo {
        @include media-breakpoint-up(sm) {
            flex-grow: 1;
            flex-basis: 0;
        }

        img {
            block-size: auto;

            @include media-breakpoint-down(sm) {
                max-width: 85px;
            }
        }
    }

    &-buttons {
        .btn-secondary {
            align-self: stretch;
            height: 40px;
            min-width: 40px;
        }

        &-phones {
            .btn-icon {
                mask-image: url(../img/sprite.svg#include--header-phone-icon);
                width: 20px;
                height: 20px;
            }
        }

        @include media-breakpoint-down(md) {
            &-languages {
                width: 40px;
                flex: 0 0 40px;
                padding-left: 0.625rem !important;
                padding-right: 0.625rem !important;
                justify-content: center;

                &.with-dropdown {
                    &::after {
                        display: none;
                    }
                }
            }

            &-currencies {
                &.with-dropdown {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        &-user,
        &-cart,
        &-menu {
            padding-left: 0.625rem !important;
            padding-right: 0.625rem !important;
        }

        &-user {
            .btn-icon {
                mask-image: url(../img/sprite.svg#include--header-user-icon);
                width: 20px;
                height: 20px;
            }
        }

        &-cart {
            position: relative;

            .btn-icon {
                mask-image: url(../img/sprite.svg#include--header-cart-icon);
                width: 20px;
                height: 20px;
            }

            &-quantity {
                display: block;
                min-width: 14px;
                background-color: $primaryColor;
                font-weight: 400;
                font-size: 9px;
                height: 14px;
                line-height: 14px;
                border-radius: 16px;
                position: absolute;
                top: 6px;
                right: 6px;
                color: #fff;
            }
        }

        &-menu {
            height: 40px;
            flex: 0 0 40px;
            justify-content: center;

            .btn-icon {
                mask: url(../img/sprite.svg#include--header-menu-icon);
                width: 17px;
                height: 15px;
            }
        }
    }

    .dropdown {
        &-menu {
            top: calc(100% + 0.5rem) !important;
            z-index: 1030;

            @include media-breakpoint-down(sm) {
                right: -95px !important;

                &::after {
                    right: calc(10% + 76px);
                }
            }
        }

        &-header {
            white-space: normal;
        }
    }
}
