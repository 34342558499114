.checkout-success {
    background: #FFFFFF;
    box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05);
    padding: 1rem 1.5rem;

    @include media-breakpoint-up(md) {
        padding: 2.5rem;
        max-width: 736px;
    }

    &-title,
    &-text,
    img {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2.5rem;
        }
    }

    &-title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-transform: uppercase;

        b {
            font-weight: 700;
        }
    }

    img {
        max-width: 100%;
        block-size: auto;
    }

    &-text {
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 0.875rem;

            br {
                display: none;
            }
        }
    }
}